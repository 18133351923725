import './BillingPage.css'

import React, { useEffect, useState } from 'react'

import { functions } from '../firebase'
import { getAuth } from 'firebase/auth'
import { httpsCallable } from 'firebase/functions'
import { logAnalyticsEvent } from '../services/storyGenerationService'

interface CreditPackage {
  credits: number
  price: number
}

// Keep in sync with functions/src/constants.ts
const creditPackages: CreditPackage[] = [
  { credits: 1, price: 1 },
  { credits: 5, price: 5 },
  { credits: 10, price: 8 },
  { credits: 20, price: 15 },
]

const BillingPage: React.FC = () => {
  const [isProcessing, setIsProcessing] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const auth = getAuth()
  const user = auth.currentUser

  useEffect(() => {
    // Log page view event when the component mounts
    logAnalyticsEvent('view_billing_page')
  }, [])

  const handlePurchase = async (credits: number) => {
    setIsProcessing(true)
    setError(null)
    try {
      if (!user) {
        throw new Error('User not authenticated')
      }

      // Log attempt to purchase credits
      logAnalyticsEvent('attempt_purchase', {
        credits: credits,
        userId: user.uid,
      })

      const createCheckoutSession = httpsCallable(
        functions,
        'createCheckoutSession'
      )
      const result = await createCheckoutSession({
        email: user.email,
        uid: user.uid,
        credits,
      })
      const { sessionUrl } = result.data as { sessionUrl: string }

      // Log successful creation of checkout session
      logAnalyticsEvent('checkout_session_created', {
        credits: credits,
        userId: user.uid,
      })

      window.open(sessionUrl)
    } catch (error) {
      console.error('Error creating checkout session:', error)
      setError('Failed to initiate payment. Please try again.')

      // Log error in creating checkout session
      logAnalyticsEvent('checkout_session_error', {
        credits: credits,
        userId: user?.uid,
        error: (error as Error).message,
      })
    } finally {
      setIsProcessing(false)
    }
  }

  return (
    <div className="billing-container">
      <div className="billing-card">
        <h1>Purchase Credits</h1>
        <div className="credit-packages">
          {creditPackages.map((pkg) => (
            <div key={pkg.credits} className="credit-package">
              <h2>
                {pkg.credits} Credit{pkg.credits > 1 ? 's' : ''}
              </h2>
              <p>${pkg.price}</p>
              <button
                onClick={() => {
                  handlePurchase(pkg.credits)
                }}
                disabled={isProcessing}
              >
                {isProcessing ? 'Processing...' : 'Buy Now'}
              </button>
            </div>
          ))}
        </div>
        {error && <div className="error">{error}</div>}
      </div>
    </div>
  )
}

export default BillingPage
