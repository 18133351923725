import { GoogleAuthProvider, getAuth, signInWithPopup } from 'firebase/auth'
import React, { useEffect, useState } from 'react'
import { doc, serverTimestamp, setDoc } from 'firebase/firestore'

import { Link } from 'react-router-dom'
import { db } from '../firebase'
import { logAnalyticsEvent } from '../services/storyGenerationService'
import { useNavigate } from 'react-router-dom'

const Auth: React.FC = () => {
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [showToSCheckbox, setShowToSCheckbox] = useState(true)
  const [agreedToTerms, setAgreedToTerms] = useState(false)
  const navigate = useNavigate()

  const auth = getAuth()
  const googleProvider = new GoogleAuthProvider()

  useEffect(() => {
    const hasAcceptedToS = localStorage.getItem('hasAcceptedToS') === 'true'
    setShowToSCheckbox(!hasAcceptedToS)
    setAgreedToTerms(hasAcceptedToS)
  }, [])

  const handleGoogleSignIn = async () => {
    if (!agreedToTerms) {
      setError(
        'You must agree to the Terms of Service and Privacy Policy to continue.'
      )
      return
    }

    setError('')
    setLoading(true)
    try {
      const result = await signInWithPopup(auth, googleProvider)
      const user = result.user

      const userRef = doc(db, 'users', user.uid)
      await setDoc(
        userRef,
        {
          email: user.email,
          displayName: user.displayName,
          lastLogin: serverTimestamp(),
        },
        { merge: true }
      )

      if (!user.email) {
        setError('Email required.')
        return
      }
      localStorage.setItem('hasAcceptedToS', 'true')
      logAnalyticsEvent('login', { method: 'google' })
      navigate('/')
    } catch (error) {
      setError((error as Error).message)
      logAnalyticsEvent('login_error', { error: (error as Error).message })
    } finally {
      setLoading(false)
    }
  }

  const handleTermsAgreement = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAgreedToTerms(e.target.checked)
    if (e.target.checked) {
      localStorage.setItem('hasAcceptedToS', 'true')
    }
  }

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="max-w-md w-full bg-white rounded-lg shadow-md p-8">
        <h2 className="text-2xl font-bold text-center mb-4">
          Welcome to iyagi
        </h2>
        <p className="text-center text-gray-600 mb-6">
          Log in to create personalized stories for children
        </p>
        {showToSCheckbox && (
          <div className="flex items-start mb-4">
            <div className="flex items-center h-5">
              <input
                type="checkbox"
                id="termsAgreement"
                checked={agreedToTerms}
                onChange={handleTermsAgreement}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor="termsAgreement" className="text-gray-600">
                I agree to the{' '}
                <Link
                  to="/terms-of-service"
                  className="text-blue-500 hover:underline"
                  target="_blank"
                >
                  Terms of Service
                </Link>{' '}
                and{' '}
                <Link
                  to="/privacy-policy"
                  className="text-blue-500 hover:underline"
                  target="_blank"
                >
                  Privacy Policy
                </Link>
              </label>
            </div>
          </div>
        )}
        <button
          onClick={handleGoogleSignIn}
          className="google-sign-in hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed"
          disabled={loading || !agreedToTerms}
        >
          <span className="mr-2 google-icon"></span>
          {loading ? 'Signing in...' : 'Sign in with Google to Start'}
        </button>
        {error && <p className="mt-4 text-red-500 text-center">{error}</p>}
      </div>
    </div>
  )
}

export default Auth
