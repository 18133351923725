import React from 'react'

interface ReadingLevelSVGProps {
  level: number
}

const ReadingLevelSVG: React.FC<ReadingLevelSVGProps> = ({ level }) => {
  const outlineColor = '#ffffff'
  const filledColor = '#d66853'

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 95"
      className="reading-level-svg"
    >
      <defs>
        <filter id="drop-shadow" x="-20%" y="-20%" width="140%" height="140%">
          <feGaussianBlur in="SourceAlpha" stdDeviation="3" />
          <feOffset dx="2" dy="2" result="offsetblur" />
          <feFlood floodColor="rgba(0,0,0,0.5)" />
          <feComposite in2="offsetblur" operator="in" />
          <feMerge>
            <feMergeNode />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <g filter="url(#drop-shadow)">
        <polygon
          points="50,10 90,40 72,85 28,85 10,40"
          fill="none"
          stroke={outlineColor}
          strokeWidth="10"
        />
        {level === 1 && (
          <path
            d="M50,10 L90,40"
            fill="none"
            stroke={filledColor}
            strokeWidth="10"
            strokeLinecap="butt"
          />
        )}
        {level === 2 && (
          <path
            d="M50,10 L90,40 L72,85"
            fill="none"
            stroke={filledColor}
            strokeWidth="10"
            strokeLinecap="butt"
            strokeLinejoin="miter"
          />
        )}
        {level === 3 && (
          <path
            d="M50,10 L90,40 L72,85 L28,85"
            fill="none"
            stroke={filledColor}
            strokeWidth="10"
            strokeLinecap="butt"
            strokeLinejoin="miter"
          />
        )}
        {level === 4 && (
          <path
            d="M50,10 L90,40 L72,85 L28,85 L10,40"
            fill="none"
            stroke={filledColor}
            strokeWidth="10"
            strokeLinecap="butt"
            strokeLinejoin="miter"
          />
        )}
        {level === 5 && (
          <polygon
            points="50,10 90,40 72,85 28,85 10,40"
            fill="none"
            stroke={filledColor}
            strokeWidth="10"
          />
        )}
      </g>
    </svg>
  )
}

export default ReadingLevelSVG
