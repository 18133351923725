import '../App.css'
import './StoryGenerator.css' // Add this line

import {
  CREDITS_PER_STORY,
  getUserCredits,
  useCredits,
} from '../services/userService'
import { Link, useNavigate } from 'react-router-dom'
import React, { useEffect, useRef, useState } from 'react'
import { ReadingLevel, readingLevels } from '../types'
import {
  generateStoryWithImages,
  logAnalyticsEvent,
} from '../services/storyGenerationService'
import { sampleAnimals, sampleLessons } from '../data/sampleData'

import { Tooltip } from 'react-tooltip'
import { getAuth } from 'firebase/auth'
import { getValue } from 'firebase/remote-config'
import { remoteConfig } from '../firebase'
import { useWhitelistStatus } from '../hooks/useWhitelistStatus'

const MIN_PAGES = 1
const MAX_PAGES = 20
const DEFAULT_PAGES = 12

const StoryGenerator: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [numPages, setNumPages] = useState<string>(() => {
    const storedValue =
      localStorage.getItem('numPages') || DEFAULT_PAGES.toString()
    return Math.min(
      Math.max(parseInt(storedValue, 10), MIN_PAGES),
      MAX_PAGES
    ).toString()
  })
  const [animal, setAnimal] = useState(
    () => localStorage.getItem('animal') || 'penguin'
  )
  const [lesson, setLesson] = useState(
    () => localStorage.getItem('lesson') || 'sharing is caring'
  )
  const [userCredits, setUserCredits] = useState<number | null>(null)
  const [readingLevel, setReadingLevel] = useState<string>(() => {
    return localStorage.getItem('readingLevel') || 'Level 2'
  })
  const [isColoringBook, setIsColoringBook] = useState<boolean>(() => {
    return localStorage.getItem('isColoringBook') === 'true'
  })
  const auth = getAuth()
  const user = auth.currentUser
  const { isWhitelisted } = useWhitelistStatus()

  const billingEnabled = getValue(remoteConfig, 'billing_enabled').asBoolean()

  const navigate = useNavigate()

  useEffect(() => {
    localStorage.setItem('numPages', numPages)
    localStorage.setItem('animal', animal)
    localStorage.setItem('lesson', lesson)
    localStorage.setItem('readingLevel', readingLevel)
    localStorage.setItem('isColoringBook', isColoringBook.toString())
  }, [numPages, animal, lesson, readingLevel, isColoringBook])

  useEffect(() => {
    // Log page view event when the component mounts
    logAnalyticsEvent('view_story_generator')
  }, [])

  const isFormValid = () => {
    return (
      numPages !== '' &&
      parseInt(numPages, 10) >= MIN_PAGES &&
      parseInt(numPages, 10) <= MAX_PAGES &&
      animal.trim() !== '' &&
      lesson.trim() !== ''
    )
  }

  useEffect(() => {
    const fetchUserCredits = async () => {
      if (user) {
        const credits = await getUserCredits(user.uid)
        setUserCredits(credits)
      }
    }
    fetchUserCredits()
  }, [])

  const generateStoryBook = async () => {
    if (!isFormValid()) {
      setError('Please fill in all fields correctly before creating a story.')
      logAnalyticsEvent('generate_story_error', {
        error: 'Invalid form',
        numPages,
        animal,
        lesson,
        isWhitelisted,
      })
      return
    }

    setIsLoading(true)
    setError(null)
    try {
      if (!user) {
        throw new Error('User not authenticated')
      }

      // Check if user has enough credits
      const hasEnoughCredits = await useCredits(user.uid)
      if (billingEnabled && !isWhitelisted && !hasEnoughCredits) {
        throw new Error('Not enough credits to generate a story')
      }

      logAnalyticsEvent('generate_story_start', {
        numPages: parseInt(numPages, 10),
        animal,
        lesson,
        isWhitelisted,
      })

      const { storyId } = await generateStoryWithImages(
        parseInt(numPages, 10),
        animal,
        lesson,
        user.uid,
        readingLevel,
        isColoringBook
      )

      // Update user credits after successful generation
      const updatedCredits = await getUserCredits(user.uid)
      setUserCredits(updatedCredits)

      logAnalyticsEvent('generate_story_success', { storyId })
      navigate(`/story/${storyId}/0`)
    } catch (error) {
      console.error('Error generating story:', error)
      setError((error as Error).message)
      logAnalyticsEvent('generate_story_error', {
        error: (error as Error).message,
        numPages,
        animal,
        lesson,
        isWhitelisted,
      })
    } finally {
      setIsLoading(false)
    }
  }

  const [isRollingAnimal, setIsRollingAnimal] = useState(false)
  const [isRollingLesson, setIsRollingLesson] = useState(false)

  const handleRandomAnimal = () => {
    setIsRollingAnimal(true)
    setTimeout(() => {
      const randomIndex = Math.floor(Math.random() * sampleAnimals.length)
      setAnimal(sampleAnimals[randomIndex])
      setIsRollingAnimal(false)
      logAnalyticsEvent('random_animal_selected', {
        animal: sampleAnimals[randomIndex],
      })
    }, 200)
  }

  const handleRandomLesson = () => {
    setIsRollingLesson(true)
    setTimeout(() => {
      const randomIndex = Math.floor(Math.random() * sampleLessons.length)
      setLesson(sampleLessons[randomIndex])
      setIsRollingLesson(false)
      logAnalyticsEvent('random_lesson_selected', {
        lesson: sampleLessons[randomIndex],
      })
    }, 200)
  }

  const animalRef = useRef<HTMLTextAreaElement>(null)
  const lessonRef = useRef<HTMLTextAreaElement>(null)

  const adjustTextareaHeight = (textarea: HTMLTextAreaElement) => {
    textarea.style.height = 'auto'
    textarea.style.height = `${textarea.scrollHeight}px`
  }

  useEffect(() => {
    if (animalRef.current) adjustTextareaHeight(animalRef.current)
    if (lessonRef.current) adjustTextareaHeight(lessonRef.current)
  }, [animal, lesson])

  const handleNumPagesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    const parsedValue = parseInt(value, 10)
    if (!isNaN(parsedValue)) {
      const clampedValue = Math.min(Math.max(parsedValue, MIN_PAGES), MAX_PAGES)
      setNumPages(clampedValue.toString())
    } else {
      setNumPages('')
    }
  }

  const readingLevelDescriptions: Record<ReadingLevel, string> = {
    'Level 1': 'Very Simple, 1 short sentence per page',
    'Level 2': '1-2 short sentences per page',
    'Level 3': '2-3 sentences per page',
    'Level 4': '3-4 sentences per page',
    'Level 5': '4-5 sentences or short paragraph per page',
  }

  const MAX_ANIMAL_LENGTH = 100
  const MAX_LESSON_LENGTH = 150
  const SHOW_COUNT_THRESHOLD = 0.8 // Show count when 80% of max length is reached

  const showAnimalCount =
    animal.length >= MAX_ANIMAL_LENGTH * SHOW_COUNT_THRESHOLD
  const showLessonCount =
    lesson.length >= MAX_LESSON_LENGTH * SHOW_COUNT_THRESHOLD

  const handleAnimalChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value.slice(0, MAX_ANIMAL_LENGTH)
    setAnimal(value)
    adjustTextareaHeight(e.target)
  }

  const handleLessonChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value.slice(0, MAX_LESSON_LENGTH)
    setLesson(value)
    adjustTextareaHeight(e.target)
  }

  return (
    <>
      <div className="card-container">
        <div className="card">
          <h1>Create your own!</h1>
          {billingEnabled && !isWhitelisted && (
            <div className="credits-section">
              {userCredits !== null && (
                <div className="credits-info">
                  <span className="credits-count">{userCredits}</span>
                  <span className="credits-text">
                    credit{userCredits !== 1 ? 's' : ''} available
                  </span>
                </div>
              )}
              <Link
                to="/billing"
                className="add-credits-button"
                onClick={() => logAnalyticsEvent('click_add_credits')}
              >
                <span className="add-icon">+</span>
                Add Credits
              </Link>
            </div>
          )}
          <div className="input-group">
            <label htmlFor="numPages">Number of pages:</label>
            <input
              id="numPages"
              type="number"
              value={numPages}
              onChange={handleNumPagesChange}
              min={MIN_PAGES}
              max={MAX_PAGES}
            />
          </div>
          <div className="input-group">
            <label htmlFor="animal">Animal:</label>
            <div className="input-with-button">
              <textarea
                id="animal"
                ref={animalRef}
                value={animal}
                onChange={handleAnimalChange}
                rows={1}
                maxLength={MAX_ANIMAL_LENGTH}
              />
              <button
                onClick={handleRandomAnimal}
                className={`random-button ${isRollingAnimal ? 'rolling' : ''}`}
                disabled={isRollingAnimal}
              >
                🎲
              </button>
            </div>
            {showAnimalCount && (
              <small className="char-count">
                {animal.length}/{MAX_ANIMAL_LENGTH}
              </small>
            )}
          </div>
          <div className="input-group">
            <label htmlFor="lesson">Lesson:</label>
            <div className="input-with-button">
              <textarea
                id="lesson"
                ref={lessonRef}
                value={lesson}
                onChange={handleLessonChange}
                rows={1}
                maxLength={MAX_LESSON_LENGTH}
              />
              <button
                onClick={handleRandomLesson}
                className={`random-button ${isRollingLesson ? 'rolling' : ''}`}
                disabled={isRollingLesson}
              >
                🎲
              </button>
            </div>
            {showLessonCount && (
              <small className="char-count">
                {lesson.length}/{MAX_LESSON_LENGTH}
              </small>
            )}
          </div>
          <div className="input-group">
            <label htmlFor="readingLevel">Reading Level:</label>
            <select
              id="readingLevel"
              value={readingLevel}
              onChange={(e) => setReadingLevel(e.target.value)}
            >
              {readingLevels.map((level) => (
                <option key={level} value={level}>
                  {level}: {readingLevelDescriptions[level]}
                </option>
              ))}
            </select>
          </div>
          <div className="input-group">
            <label className="inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                checked={isColoringBook}
                onChange={(e) => setIsColoringBook(e.target.checked)}
                className="sr-only peer"
              />
              <div className="relative w-7 h-4 bg-gray-200 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-[#d8932b] rounded-full peer dark:bg-[#71552b] peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-3 after:w-3 after:transition-all dark:border-gray-600 peer-checked:bg-[#d8932b]"></div>
              <span className="ms-3 text-sm font-medium text-black">
                Coloring Book Style
                <span
                  className="ml-2 cursor-help"
                  data-tooltip-id="coloring-book-tooltip"
                  data-tooltip-content="Generate black and white line drawings suitable for coloring that can be printed out"
                >
                  ℹ️
                </span>
              </span>
            </label>
          </div>
          <Tooltip id="coloring-book-tooltip" place="bottom" />
          <button
            onClick={generateStoryBook}
            disabled={
              billingEnabled &&
              !isWhitelisted &&
              (isLoading ||
                !isFormValid() ||
                (userCredits !== null && userCredits < CREDITS_PER_STORY))
            }
            className="text-white"
          >
            {isLoading
              ? 'Creating...'
              : billingEnabled && !isWhitelisted
                ? `Create (${CREDITS_PER_STORY} credit${CREDITS_PER_STORY !== 1 ? 's' : ''})`
                : 'Create'}
          </button>
          <Link
            to="/"
            className="browse-link"
            onClick={() => logAnalyticsEvent('click_browse_stories')}
          >
            Browse
          </Link>
          {error && <div className="error">{error}</div>}
        </div>
      </div>
    </>
  )
}

export default StoryGenerator
