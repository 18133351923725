import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { useEffect, useState } from 'react'

import { checkIsAdmin } from '../services/storyGenerationService'
import { useEmulator } from '../firebase'

export const useAdminStatus = () => {
  const [isAdmin, setIsAdmin] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (useEmulator) {
      setIsAdmin(true)
      setLoading(false)
      return
    }
    const auth = getAuth()
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const adminStatus = await checkIsAdmin(user.uid)
        setIsAdmin(adminStatus)
      } else {
        setIsAdmin(false)
      }
      setLoading(false)
    })

    return () => unsubscribe()
  }, [])

  return { isAdmin, loading }
}
