import { doc, getDoc } from 'firebase/firestore'
import { useEffect, useState } from 'react'

import { db } from '../firebase'
import { getAuth } from 'firebase/auth'

const WHITELIST_COLLECTION = 'whitelist'

export const useWhitelistStatus = () => {
  const [isWhitelisted, setIsWhitelisted] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const auth = getAuth()
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user && user.email) {
        const isUserWhitelisted = await checkWhitelistStatus(user.email)
        setIsWhitelisted(isUserWhitelisted)
      } else {
        setIsWhitelisted(false)
      }
      setLoading(false)
    })

    return () => unsubscribe()
  }, [])

  const checkWhitelistStatus = async (email: string): Promise<boolean> => {
    const whitelistRef = doc(db, WHITELIST_COLLECTION, email)
    const whitelistSnap = await getDoc(whitelistRef)
    return whitelistSnap.exists()
  }

  return { isWhitelisted, loading }
}
