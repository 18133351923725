import '../App.css'
import './BrowseStories.css'

import { Link, useNavigate } from 'react-router-dom'
import React, { useCallback, useEffect, useState } from 'react'
import { faFilter, faSort } from '@fortawesome/free-solid-svg-icons'
import {
  getPublishedStories,
  logAnalyticsEvent,
} from '../services/storyGenerationService'

import { DocumentData } from '@firebase/firestore'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import InfiniteScroll from 'react-infinite-scroll-component'
import LikeButton from './LikeButton'
import ReadingLevelSVG from './ReadingLevelSVG'
import { getAuth } from 'firebase/auth'

// Update the Story interface
interface Story {
  id: string
  title: string
  animal: string
  lesson: string
  coverImageUrl: string
  isLiked: boolean
  readingLevel?: number
  likeCount: number
}

const PAGE_SIZE = 15

const BrowseStories: React.FC = () => {
  const [stories, setStories] = useState<Story[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const [hasMore, setHasMore] = useState(true)
  const [lastVisible, setLastVisible] = useState<DocumentData | null>(null)
  const [filterOption, setFilterOption] = useState('all')
  const [sortOption, setSortOption] = useState('newest') // Add this line
  const auth = getAuth()
  const navigate = useNavigate()

  const fetchStories = useCallback(
    async (lastVisibleToUse: DocumentData | null = null) => {
      console.log('Fetching stories...')
      setLoading(true)
      try {
        const { stories: fetchedStories, lastVisible: fetchedLastVisible } =
          await getPublishedStories(
            lastVisibleToUse,
            PAGE_SIZE,
            auth.currentUser?.uid || null,
            filterOption === 'liked',
            filterOption === 'my',
            sortOption // Add this parameter
          )
        console.log('Fetched stories:', fetchedStories)
        setStories((prevStories) =>
          lastVisibleToUse === null
            ? fetchedStories
            : [...prevStories, ...fetchedStories]
        )
        setLastVisible(fetchedLastVisible)
        setHasMore(fetchedStories.length === PAGE_SIZE)
      } catch (err) {
        console.error('Error fetching stories:', err)
        setError('Failed to load stories. Please try again later.')
      } finally {
        setLoading(false)
      }
    },
    [filterOption, auth.currentUser?.uid, sortOption] // Add sortOption to the dependency array
  )

  useEffect(() => {
    setStories([])
    setLastVisible(null)
    setHasMore(true)
    fetchStories(null)
  }, [fetchStories])

  const handleFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newFilterOption = event.target.value
    setFilterOption(newFilterOption)
    logAnalyticsEvent('filter_stories', { filter: newFilterOption })
  }

  const handleSortChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newSortOption = event.target.value
    setSortOption(newSortOption)
    logAnalyticsEvent('sort_stories', { sort: newSortOption })
  }

  const handleAuthRequired = () => {
    const confirmSignIn = window.confirm(
      'Please sign in to like stories. Would you like to sign in now?'
    )
    if (confirmSignIn) {
      navigate('/login')
    }
  }

  if (error) return <div className="error">{error}</div>

  return (
    <div className="browse-stories">
      <div className="filter-container">
        <div className="filter-left">
          <FontAwesomeIcon icon={faFilter} />
          <select value={filterOption} onChange={handleFilterChange}>
            <option value="all">All Stories</option>
            <option value="liked">Liked Stories</option>
            <option value="my">My Stories</option>
          </select>
        </div>
        <div className="filter-right">
          <FontAwesomeIcon icon={faSort} />
          <select value={sortOption} onChange={handleSortChange}>
            <option value="newest">Newest</option>
            <option value="most-liked">Most Liked</option>
          </select>
        </div>
      </div>
      <InfiniteScroll
        dataLength={stories.length}
        next={() => {
          fetchStories(lastVisible)
        }}
        hasMore={hasMore}
        loader={<h4>Loading more stories...</h4>}
        endMessage={<p>No more stories to load.</p>}
      >
        <div className="story-grid">
          {loading && stories.length === 0 ? (
            <p>Loading...</p>
          ) : stories.length === 0 ? (
            <p>No stories found.</p>
          ) : (
            stories.map((story) => (
              <div key={story.id} className="story-thumbnail-container">
                <Link to={`/story/${story.id}/0`} className="story-thumbnail">
                  <img src={story.coverImageUrl} alt={story.title} />
                  <p>{story.title || story.animal}</p>
                  <div className="story-lesson-hover">
                    <p>{story.lesson}</p>
                  </div>
                  <div className="reading-level">
                    <ReadingLevelSVG level={story.readingLevel || 2} />
                  </div>
                </Link>
                <div className="story-actions">
                  <LikeButton
                    storyId={story.id}
                    initialLikeStatus={story.isLiked}
                    onAuthRequired={handleAuthRequired}
                  />
                  {story.likeCount > 0 && (
                    <span className="like-count">{story.likeCount}</span>
                  )}
                </div>
              </div>
            ))
          )}
        </div>
      </InfiniteScroll>
    </div>
  )
}

export default BrowseStories
