import React, { useEffect, useState } from 'react'
import ReactMarkdown, { Components } from 'react-markdown'
import remarkGfm from 'remark-gfm'

interface MarkdownRendererProps {
  filePath: string
}

const MarkdownRenderer: React.FC<MarkdownRendererProps> = ({ filePath }) => {
  const [content, setContent] = useState('')

  useEffect(() => {
    fetch(filePath)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch markdown content')
        }
        return response.text()
      })
      .then((text) => setContent(text))
      .catch((error) => console.error('Error fetching markdown:', error))
  }, [filePath])

  const components: Components = {
    p: ({ children }) => <p style={{ whiteSpace: 'pre-line' }}>{children}</p>,
  }

  return (
    <div className="max-w-3xl mx-auto p-6">
      <ReactMarkdown
        className="prose prose-sm sm:prose lg:prose-lg xl:prose-xl"
        remarkPlugins={[remarkGfm]}
        components={components}
      >
        {content}
      </ReactMarkdown>
    </div>
  )
}

export default MarkdownRenderer
