import './StoryBook.css'

import { Link, useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import {
  faHouse,
  faMagicWandSparkles,
  faRandom,
  faShare,
} from '@fortawesome/free-solid-svg-icons'
import {
  getRandomStoryId,
  getStoryLikeStatus,
  toggleLikeStory,
} from '../services/storyGenerationService'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getAuth } from 'firebase/auth'
import { getValue } from 'firebase/remote-config'
import { faHeart as regularHeart } from '@fortawesome/free-regular-svg-icons'
import { remoteConfig } from '../firebase'
import { faHeart as solidHeart } from '@fortawesome/free-solid-svg-icons'
import { useWhitelistStatus } from '../hooks/useWhitelistStatus'

const TheEndPage: React.FC<{
  storyId: string
  title: string
}> = ({ storyId, title }) => {
  const [isLiked, setIsLiked] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const auth = getAuth()
  const { isWhitelisted } = useWhitelistStatus()
  const [isLoadingRandom, setIsLoadingRandom] = useState(false)
  const navigate = useNavigate()
  const [shareMessage, setShareMessage] = useState('')
  const billingEnabled = getValue(remoteConfig, 'billing_enabled').asBoolean()

  useEffect(() => {
    const fetchLikeStatus = async () => {
      if (auth.currentUser) {
        try {
          const likeStatus = await getStoryLikeStatus(
            storyId,
            auth.currentUser.uid
          )
          setIsLiked(likeStatus)
        } catch (error) {
          console.error('Error fetching status:', error)
        }
      }
    }

    fetchLikeStatus()
  }, [storyId, auth.currentUser])

  const handleLike = async () => {
    if (!auth.currentUser) {
      // Handle unauthenticated user (e.g., show login prompt)
      return
    }

    setIsLoading(true)
    try {
      await toggleLikeStory(storyId, auth.currentUser)
      setIsLiked((prev) => !prev)
    } catch (error) {
      console.error('Error toggling like:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleRandomStory = async () => {
    if (isLoadingRandom) {
      return
    }
    setIsLoadingRandom(true)
    try {
      const randomStoryId = await getRandomStoryId()
      if (randomStoryId) {
        navigate(`/story/${randomStoryId}/0`)
      } else {
        console.error('No random story found')
      }
    } catch (error) {
      console.error('Error fetching random story:', error)
    } finally {
      setIsLoadingRandom(false)
    }
  }

  const handleShare = async () => {
    const url = `${window.location.origin}/story/${storyId}/0`
    const shareTitle = `Check out this Iyagi!`
    const text = `${title}`
    const fullShareText = `${shareTitle}\n\n${text}\n\nRead it here: ${url}`

    const isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )

    if (isMobile && navigator.share) {
      try {
        await navigator.share({ title: shareTitle, text, url })
        setShareMessage('Shared successfully!')
      } catch (error) {
        console.error('Error sharing:', error)
        setShareMessage('Error sharing. Try copying the link instead.')
      }
    } else {
      try {
        await navigator.clipboard.writeText(fullShareText)
        setShareMessage('Story details copied to clipboard!')
      } catch (error) {
        console.error('Error copying to clipboard:', error)
        setShareMessage('Error copying story details. Please try again.')
      }
    }

    setTimeout(() => setShareMessage(''), 3000)
  }

  return (
    <div className="title-page flex flex-col justify-center items-center h-full">
      <img
        src="/done_reading.png"
        alt="Done Reading"
        className="done-reading-image"
      />
      <h2 className="end-title">The End</h2>
      <p className="story-title end-title">{title}</p>

      <div className="end-page-actions">
        {auth.currentUser ? (
          isWhitelisted || !billingEnabled ? (
            <Link to="/create" className="primary-action-button">
              <FontAwesomeIcon icon={faMagicWandSparkles} className="mr-2" />
              Create Another Story
            </Link>
          ) : (
            <p className="info-text">
              You don't have permission to create stories yet.
            </p>
          )
        ) : (
          <Link to="/login" className="primary-action-button">
            Login to Create Stories!
          </Link>
        )}

        <button
          className={`secondary-action-button ${isLiked ? 'liked' : ''}`}
          onClick={handleLike}
          disabled={isLoading}
        >
          <FontAwesomeIcon
            icon={isLiked ? solidHeart : regularHeart}
            className="mr-2"
          />
          {isLiked ? 'Liked!' : 'Like this story'}
        </button>

        <button onClick={handleShare} className="secondary-action-button">
          <FontAwesomeIcon icon={faShare} className="mr-2" />
          Share Story
        </button>
      </div>

      {shareMessage && <p className="share-message">{shareMessage}</p>}

      <div className="additional-actions">
        <Link to="/" className="text-link">
          <FontAwesomeIcon icon={faHouse} className="mr-2" />
          Browse More Stories
        </Link>

        <div onClick={handleRandomStory} className="text-link">
          <FontAwesomeIcon icon={faRandom} className="mr-2" />
          {isLoadingRandom ? 'Loading...' : 'Read Another Story'}
        </div>
      </div>
    </div>
  )
}

export default TheEndPage
