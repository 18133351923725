import { doc, getDoc, increment, setDoc, updateDoc } from 'firebase/firestore'

import { db } from '../firebase'

const CREDITS_PER_STORY = 1

export async function getUserCredits(userId: string): Promise<number> {
  const userRef = doc(db, 'users', userId)
  const userDoc = await getDoc(userRef)

  if (userDoc.exists()) {
    return userDoc.data().credits || 0
  } else {
    // If the user document doesn't exist, create it with 0 credits
    await setDoc(userRef, { credits: 0 })
    return 0
  }
}

export async function addCredits(
  userId: string,
  amount: number
): Promise<void> {
  const userRef = doc(db, 'users', userId)
  await updateDoc(userRef, {
    credits: increment(amount),
  })
}

export async function useCredits(
  userId: string,
  amount: number = CREDITS_PER_STORY
): Promise<boolean> {
  const userRef = doc(db, 'users', userId)
  const userDoc = await getDoc(userRef)

  if (userDoc.exists()) {
    const currentCredits = userDoc.data().credits || 0
    if (currentCredits >= amount) {
      await updateDoc(userRef, {
        credits: increment(-amount),
      })
      return true
    }
  }
  return false
}

export { CREDITS_PER_STORY }
