const TitlePage: React.FC<{
  title: string
  subtitle?: string
  translatedTitle?: string
  translatedSubtitle?: string
}> = ({ title, subtitle, translatedTitle, translatedSubtitle }) => {
  return (
    <div className="title-page flex flex-col justify-center items-center h-full w-full">
      <h1 className="story-title text-5xl md:text-6xl text-center text-gray-800 font-bold mb-4">
        {title}
      </h1>
      {translatedTitle && (
        <h2 className="translated-title text-3xl md:text-4xl text-center text-gray-700 font-semibold mb-4">
          {translatedTitle}
        </h2>
      )}
      {subtitle && (
        <h2 className="text-xl md:text-2xl text-gray-600 mt-4 font-light italic max-w-md text-center">
          {subtitle}
        </h2>
      )}
      {translatedSubtitle && (
        <h3 className="translated-subtitle text-lg md:text-xl text-gray-500 mt-2 font-light italic max-w-md text-center">
          {translatedSubtitle}
        </h3>
      )}
    </div>
  )
}

export default TitlePage
