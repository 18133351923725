import { connectAuthEmulator, getAuth } from 'firebase/auth'
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore'
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions'
import { fetchAndActivate, getRemoteConfig } from 'firebase/remote-config'

import { getAnalytics } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'

// Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyBV4EKLp-0PK_ASlU53hcDDc0kAg9UuvGM',
  authDomain: 'iyagi.space',
  projectId: 'ai-stories-c7e7d',
  storageBucket: 'ai-stories-c7e7d.appspot.com',
  messagingSenderId: '646855595634',
  appId: '1:646855595634:web:7233eb644c2d6cdde514eb',
  measurementId: 'G-GBP27S6X9B',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
export const db = getFirestore(app)
export const auth = getAuth(app)
export const functions = getFunctions(app)

export const remoteConfig = getRemoteConfig(app)
remoteConfig.settings.minimumFetchIntervalMillis = 3600000 // 1 hour
fetchAndActivate(remoteConfig)

// set this to true to connect to the emulators
export const useEmulator = process.env.REACT_APP_USE_EMULATOR === 'true'
if (useEmulator) {
  try {
    connectFunctionsEmulator(functions, '127.0.0.1', 5001)
    console.log('Connected to Functions emulator')

    connectFirestoreEmulator(db, 'localhost', 8080)
    console.log('Connected to Firestore emulator')

    // Connect to Auth emulator
    connectAuthEmulator(auth, 'http://localhost:9099')
    console.log('Connected to Auth emulator')
  } catch (error) {
    console.error('Error connecting to emulators:', error)
  }
}

export const analytics = getAnalytics(app)
