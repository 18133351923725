import './NavBar.css' // Add this import

import { Link, useNavigate } from 'react-router-dom'
import React, { useState } from 'react'
import {
  faBars,
  faInfoCircle,
  faShieldAlt,
  faSignInAlt,
  faSignOutAlt,
  faTimes,
  faWandMagicSparkles,
} from '@fortawesome/free-solid-svg-icons'
import { getAuth, signOut } from 'firebase/auth'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { logAnalyticsEvent } from '../services/storyGenerationService'
import { useAdminStatus } from '../hooks/useAdminStatus' // Add this import

const NavBar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { isAdmin } = useAdminStatus() // Add this line
  const auth = getAuth()
  const navigate = useNavigate()

  const toggleMenu = () => setIsOpen(!isOpen)

  const handleLogout = async () => {
    try {
      await signOut(auth)
      logAnalyticsEvent('logout')
      navigate('/login')
    } catch (error) {
      console.error('Error signing out:', error)
      logAnalyticsEvent('logout_error', { error: (error as Error).message })
    }
  }

  return (
    <nav className="navbar">
      <div className="navbar-brand">
        <Link to="/">
          <img
            src="/wordmark_landscape_tight.png"
            alt="StoryAI"
            className="navbar-logo"
          />
        </Link>
      </div>
      <button className="navbar-toggle" onClick={toggleMenu}>
        <FontAwesomeIcon icon={isOpen ? faTimes : faBars} />
      </button>
      <ul className={`navbar-menu ${isOpen ? 'is-open' : ''}`}>
        <li>
          <Link to="/about" onClick={toggleMenu}>
            <FontAwesomeIcon icon={faInfoCircle} /> About
          </Link>
        </li>
        {auth.currentUser && (
          <li>
            <Link to="/create" onClick={toggleMenu}>
              <FontAwesomeIcon icon={faWandMagicSparkles} /> Create
            </Link>
          </li>
        )}
        {isAdmin && (
          <li>
            <Link to="/admin" onClick={toggleMenu}>
              <FontAwesomeIcon icon={faShieldAlt} /> Admin
            </Link>
          </li>
        )}
        {auth.currentUser ? (
          <li>
            <div onClick={handleLogout} className="logout-link">
              <FontAwesomeIcon icon={faSignOutAlt} /> Logout
            </div>
          </li>
        ) : (
          <li>
            <Link to="/login" onClick={toggleMenu}>
              <FontAwesomeIcon icon={faSignInAlt} /> Login to Create!
            </Link>
          </li>
        )}
      </ul>
    </nav>
  )
}

export default NavBar
