import React from 'react'

export const EnglishFlag = () => (
  <span role="img" aria-label="English flag">
    🇬🇧
  </span>
)
export const SpanishFlag = () => (
  <span role="img" aria-label="Spanish flag">
    🇪🇸
  </span>
)
export const FrenchFlag = () => (
  <span role="img" aria-label="French flag">
    🇫🇷
  </span>
)
export const GermanFlag = () => (
  <span role="img" aria-label="German flag">
    🇩🇪
  </span>
)
export const ItalianFlag = () => (
  <span role="img" aria-label="Italian flag">
    🇮🇹
  </span>
)
export const PortugueseFlag = () => (
  <span role="img" aria-label="Portuguese flag">
    🇵🇹
  </span>
)
export const RussianFlag = () => (
  <span role="img" aria-label="Russian flag">
    🇷🇺
  </span>
)
export const JapaneseFlag = () => (
  <span role="img" aria-label="Japanese flag">
    🇯🇵
  </span>
)
export const KoreanFlag = () => (
  <span role="img" aria-label="Korean flag">
    🇰🇷
  </span>
)
export const ChineseFlag = () => (
  <span role="img" aria-label="Chinese flag">
    🇨🇳
  </span>
)

export const ChinesePinyinFlag = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    width="40"
    height="40"
  >
    <rect x="10" y="10" width="80" height="80" fill="#e60012" rx="10" ry="10" />
    <text
      x="50"
      y="50"
      font-family="Arial, sans-serif"
      font-size="60"
      fill="white"
      text-anchor="middle"
      dominant-baseline="central"
    >
      拼
    </text>
  </svg>
)
