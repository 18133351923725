interface StoryDataPartial {
  id: string
  pages: StoryPage[]
  title: string
  lesson: string
}

interface StoryDataExtended {
  [key: string]: string
}

export type StoryData = StoryDataPartial & StoryDataExtended

export interface StoryPage {
  text: string
  imageUrl: string
  // dynamic to support multiple languages
  [key: string]: string
}

export type ReadingLevel =
  | 'Level 1'
  | 'Level 2'
  | 'Level 3'
  | 'Level 4'
  | 'Level 5'

export const readingLevels: ReadingLevel[] = [
  'Level 1',
  'Level 2',
  'Level 3',
  'Level 4',
  'Level 5',
]

const DEFAULT_READING_LEVEL = 2

export function convertReadingLevelToNumber(
  readingLevel: string | null
): number {
  if (!readingLevel) {
    return DEFAULT_READING_LEVEL
  }
  const match = readingLevel.match(/Level (\d+)/)
  if (match) {
    return parseInt(match[1], 10)
  }
  return DEFAULT_READING_LEVEL
}
