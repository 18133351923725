import React, { useEffect, useState } from 'react'
import {
  getStoryFromFirestore,
  logAnalyticsEvent,
} from '../services/storyGenerationService'

import { DocumentData } from '@firebase/firestore'
import StoryBook from './StoryBook'
import { StoryData } from '../types'
import { useParams } from 'react-router-dom'

const SavedStory: React.FC = () => {
  const { storyId } = useParams<{
    storyId: string
  }>()
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const [imagesLoaded, setImagesLoaded] = useState(false)
  const [story, setStory] = useState<StoryData | null>(null)

  const fetchStory = async () => {
    console.log('fetch story')
    if (!storyId) return
    try {
      let storyData
      let attempts = 0
      const maxAttempts = 5 * 60 // 5 minutes (5 * 60 seconds)
      const interval = 1000 // 1 seconds

      while (attempts < maxAttempts) {
        storyData = await getStoryFromFirestore(storyId)

        if (storyData.status === 'ready') {
          const story = storyData as StoryData

          console.log('set story', story)
          setStory(story)

          // Preload images
          const imagePromises = storyData.pages.map((page: DocumentData) => {
            return new Promise((resolve, reject) => {
              const img = new Image()
              img.src = page.imageUrl
              img.onload = resolve
              img.onerror = reject
            })
          })

          Promise.all(imagePromises)
            .then(() => setImagesLoaded(true))
            .catch((error) => console.error('Error preloading images:', error))

          setIsLoading(false)
          logAnalyticsEvent('view_story', { storyId })
          break
        } else {
          attempts++
          await new Promise((resolve) => setTimeout(resolve, interval))
        }
      }

      if (attempts >= maxAttempts) {
        throw new Error('Story generation timed out')
      }
    } catch (error) {
      console.error('Error fetching story:', error)
      setError('Failed to load the story. We apologize for the inconvenience.')
      logAnalyticsEvent('view_story_error', {
        storyId,
        error: (error as Error).message,
      })
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchStory()
  }, [storyId])

  if (isLoading || !imagesLoaded) {
    return (
      <div className="loading">
        <img src="/loading.gif" alt="Loading" className="loading-logo" />
        <p>Creating your story...</p>
      </div>
    )
  }

  if (error) {
    return (
      <div className="error-container">
        <h2>Oops! Something went wrong</h2>
        <p>{error}</p>
        <p className="refund-message">
          Don't worry! Your story credit should be automatically refunded. If
          it's not, please contact us at support@iyagi.space
        </p>
        <button
          onClick={() => (window.location.href = '/')}
          className="return-home-button"
        >
          Return to Home
        </button>
      </div>
    )
  }

  if (!storyId) {
    return <div className="error">Invalid story ID</div>
  }

  return (
    <div className="saved-story">
      {story && story.pages.length > 0 ? (
        <StoryBook storyId={storyId} story={story} fetchStory={fetchStory} />
      ) : (
        <div className="message">No story found</div>
      )}
    </div>
  )
}

export default SavedStory
