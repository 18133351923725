import './AdminPage.css'

import React, { useEffect, useState } from 'react'
import {
  addToWhitelist,
  getWhitelistedUsers,
  removeFromWhitelist,
} from '../services/adminService'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { functions } from '../firebase'
import { httpsCallable } from 'firebase/functions'

const AdminPage: React.FC = () => {
  const [email, setEmail] = useState('')
  const [whitelistedUsers, setWhitelistedUsers] = useState<string[]>([])
  const [error, setError] = useState<string | null>(null)

  const [storyId, setStoryId] = useState('')
  const [postResult, setPostResult] = useState<{
    caption: string
    image: string
    imageBlob: string
  } | null>(null)

  useEffect(() => {
    fetchWhitelistedUsers()
  }, [])

  const resignAllImageUrls = async () => {
    const resignAllImageUrlsFunction = httpsCallable(
      functions,
      'resignAllImageUrls'
    )
    await resignAllImageUrlsFunction()
  }

  const generatePostForInstagram = async () => {
    if (!storyId.trim()) {
      setError('Please enter a valid Story ID')
      return
    }

    try {
      const createInstagramPostFunction = httpsCallable(
        functions,
        'createInstagramPost'
      )
      const result = await createInstagramPostFunction({
        storyId: storyId.trim(),
      })
      const resultData: { caption: string; image: string; imageBlob: string } =
        result.data as {
          caption: string
          image: string
          imageBlob: string
        }
      setPostResult(resultData)
      setError(null)
    } catch (error) {
      setError(`Failed to create Instagram post: ${error}`)
      setPostResult(null)
    }
  }

  const shareImageAsset = async (): Promise<void> => {
    if (!postResult || !postResult.image) {
      setError('No image available to share')
      return
    }
    const buffer = Uint8Array.from(atob(postResult.imageBlob), (c) =>
      c.charCodeAt(0)
    )
    console.log(postResult.caption)

    try {
      const filesArray = [
        new File([buffer], 'instagram_post.png', {
          type: 'image/png',
          lastModified: new Date().getTime(),
        }),
      ]
      console.log('AHH')
      const shareData = {
        title: 'New Story Highlight!',
        text: postResult.caption,
        files: filesArray,
      }

      if (navigator.canShare && navigator.canShare(shareData)) {
        await navigator.share(shareData)
        setError(null)
      } else {
        setError('Sharing is not supported on this device')
      }
    } catch (error) {
      setError(`Failed to share: ${error}`)
    }
  }

  const fetchWhitelistedUsers = async () => {
    try {
      const users = await getWhitelistedUsers()
      setWhitelistedUsers(users)
    } catch (_) {
      void _
      setError('Failed to fetch whitelisted users')
    }
  }

  const handleAddToWhitelist = async (emailToAdd: string) => {
    if (!isValidEmail(emailToAdd)) {
      setError('Please enter a valid email address')
      return
    }

    try {
      await addToWhitelist(emailToAdd)
      setEmail('')
      fetchWhitelistedUsers()
      setError(null)
    } catch (_) {
      void _
      setError('Failed to add user to whitelist')
    }
  }

  const handleRemoveFromWhitelist = async (userEmail: string) => {
    try {
      await removeFromWhitelist(userEmail)
      fetchWhitelistedUsers()
      setError(null)
    } catch (_) {
      void _
      setError('Failed to remove user from whitelist')
    }
  }

  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  return (
    <div className="admin-page">
      <h1 className="admin-title">Admin Page</h1>
      <div className="whitelist-form">
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter email to whitelist"
          className="email-input"
        />
        <button
          onClick={() => handleAddToWhitelist(email)}
          className="add-button"
        >
          Add to Whitelist
        </button>
      </div>
      {error && <p className="error">{error}</p>}
      <div className="user-lists">
        <div className="whitelisted-users">
          <h2>Whitelisted Users</h2>
          <ul>
            {whitelistedUsers.map((user) => (
              <li key={user}>
                <button
                  onClick={() => handleRemoveFromWhitelist(user)}
                  className="user-email whitelisted-user"
                >
                  {user}
                  <FontAwesomeIcon icon={faTrash} className="action-icon" />
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div>
        <button onClick={resignAllImageUrls}>Resign all URLs</button>
      </div>
      <div>
        <input
          type="text"
          value={storyId}
          onChange={(e) => setStoryId(e.target.value)}
          placeholder="Enter Story ID"
          className="story-id-input"
        />
        <button onClick={generatePostForInstagram}>Generate Post</button>
        {postResult && (
          <div className="instagram-post-result">
            <p>
              <strong>Caption:</strong>{' '}
              {postResult.caption.split('\n').map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  {index < postResult.caption.split('\n').length - 1 && <br />}
                </React.Fragment>
              ))}
            </p>
            {postResult.image && (
              <>
                <img
                  src={postResult.image}
                  alt="Instagram post image"
                  className="instagram-post-image"
                />
                <button onClick={shareImageAsset} className="share-button">
                  Share Post
                </button>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default AdminPage
