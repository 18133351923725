export const sampleAnimals: string[] = [
  'Elephant',
  'Lion',
  'Giraffe',
  'Monkey',
  'Zebra',
  'Penguin',
  'Kangaroo',
  'Koala',
  'Panda',
  'Tiger',
  'Hippopotamus',
  'Crocodile',
  'Dolphin',
  'Whale',
  'Octopus',
  'Bear',
  'Wolf',
  'Fox',
  'Deer',
  'Rabbit',
  'Squirrel',
  'Raccoon',
  'Owl',
  'Eagle',
  'Hawk',
  'Parrot',
  'Toucan',
  'Flamingo',
  'Peacock',
  'Ostrich',
  'Rhino',
  'Cheetah',
  'Leopard',
  'Jaguar',
  'Lynx',
  'Bobcat',
  'Panther',
  'Gorilla',
  'Chimpanzee',
  'Orangutan',
  'Lemur',
  'Sloth',
  'Armadillo',
  'Anteater',
  'Platypus',
  'Hedgehog',
  'Porcupine',
  'Beaver',
  'Otter',
  'Seal',
  'Sea Lion',
  'Walrus',
  'Polar Bear',
  'Penguin',
  'Pelican',
  'Seagull',
  'Albatross',
  'Hummingbird',
  'Woodpecker',
  'Cuckoo',
  'Pigeon',
  'Dove',
  'Swan',
  'Goose',
  'Duck',
  'Chicken',
  'Rooster',
  'Turkey',
  'Pheasant',
  'Quail',
  'Frog',
  'Toad',
  'Salamander',
  'Newt',
  'Turtle',
  'Tortoise',
  'Iguana',
  'Chameleon',
  'Gecko',
  'Snake',
  'Cobra',
  'Python',
  'Anaconda',
  'Alligator',
  'Camel',
  'Llama',
  'Alpaca',
  'Goat',
  'Sheep',
  'Cow',
  'Pig',
  'Horse',
  'Donkey',
  'Mule',
  'Zebra',
  'Bison',
  'Buffalo',
  'Moose',
  'Elk',
  'Reindeer',
  'Gazelle',
  'Antelope',
  'Wildebeest',
  'Narwhal',
  'Capybara',
  'Giant Panda',
  'Red Panda',
  'Beluga Whale',
  'Manatee',
]

export const sampleLessons: string[] = [
  'Patience is a virtue',
  'Sharing is caring',
  'Honesty is the best policy',
  'Treat others as you want to be treated',
  'Actions speak louder than words',
  'Practice makes perfect',
  'Teamwork makes the dream work',
  'Respect your elders',
  'Take responsibility for your actions',
  'Courage is facing your fears',
  'Gratitude turns what we have into enough',
  'Empathy helps us understand others',
  'Forgiveness sets you free',
  'Generosity brings joy to others',
  'Knowledge is power',
  'Kindness costs nothing',
  'Laughter is the best medicine',
  'Every cloud has a silver lining',
  "Don't judge a book by its cover",
  'The early bird catches the worm',
  "Where there's a will, there's a way",
  "Two wrongs don't make a right",
  'Slow and steady wins the race',
  'A penny saved is a penny earned',
  'Cleanliness is next to godliness',
  'The grass is always greener on the other side',
  'When in Rome, do as the Romans do',
  "Don't put all your eggs in one basket",
  'Look before you leap',
  'A picture is worth a thousand words',
  "Rome wasn't built in a day",
  'Better late than never',
  "Don't count your chickens before they hatch",
  'Practice what you preach',
  "There's no place like home",
  'The pen is mightier than the sword',
  'Actions have consequences',
  "Treat the Earth well, it wasn't given to you by your parents, it was loaned to you by your children",
  'Be the change you wish to see in the world',
  'United we stand, divided we fall',
  'Knowledge speaks, but wisdom listens',
  'A journey of a thousand miles begins with a single step',
  "You miss 100% of the shots you don't take",
  "It's not about the destination, it's about the journey",
  "Believe you can and you're halfway there",
  'To err is human, to forgive divine',
  'Where there is love, there is life',
  'The only way to do great work is to love what you do',
  'Success is not final, failure is not fatal: it is the courage to continue that counts',
  'In the middle of difficulty lies opportunity',
  'Education is the passport to the future',
  'The best way to predict the future is to create it',
  'Happiness is not something ready-made. It comes from your own actions',
  'Do what is right, not what is easy',
  'Your attitude determines your direction',
  'The only limit to our realization of tomorrow will be our doubts of today',
  'The greatest glory in living lies not in never falling, but in rising every time we fall',
  "Life is what happens to you while you're busy making other plans",
  'Strive not to be a success, but rather to be of value',
  'Stay hungry, stay foolish',
  'The future belongs to those who believe in the beauty of their dreams',
  "You can't use up creativity. The more you use, the more you have",
  'The only impossible journey is the one you never begin',
  'Live as if you were to die tomorrow. Learn as if you were to live forever',
  'Success is not how high you have climbed, but how you make a positive difference to the world',
  'The best preparation for tomorrow is doing your best today',
  "Don't watch the clock; do what it does. Keep going",
  'Believe in yourself and all that you are',
  'The secret of getting ahead is getting started',
  "Your time is limited, don't waste it living someone else's life",
  'Life is 10% what happens to you and 90% how you react to it',
  'Happiness is not by chance, but by choice',
  'Do not wait to strike till the iron is hot, but make it hot by striking',
  'If you can dream it, you can do it',
  "The harder you work for something, the greater you'll feel when you achieve it",
  "Don't be afraid to give up the good to go for the great",
  "Opportunities don't happen. You create them",
  'Success is walking from failure to failure with no loss of enthusiasm',
  'The difference between ordinary and extraordinary is that little extra',
  'The only person you are destined to become is the person you decide to be',
  'What you get by achieving your goals is not as important as what you become by achieving your goals',
  'The best revenge is massive success',
  "Everything you've ever wanted is on the other side of fear",
  'Success usually comes to those who are too busy to be looking for it',
  'If you want to lift yourself up, lift up someone else',
  'You are never too old to set another goal or to dream a new dream',
  'The mind is everything. What you think you become',
  "I have not failed. I've just found 10,000 ways that won't work",
  'A person who never made a mistake never tried anything new',
  'Sharing your toys makes playtime more fun for everyone',
  'Always say "please" and "thank you" to show good manners',
  'Wash your hands to keep germs away',
  'Eating colorful fruits and vegetables helps you grow strong',
  'Reading every day helps your brain grow',
  "It's okay to make mistakes – that's how we learn",
  'Be kind to animals and treat them gently',
  'Helping others makes you feel good inside',
  "Always tell the truth, even when it's hard",
  'Listen when others are speaking',
  'Clean up your toys to keep your room tidy',
  'Try new foods – you might discover a new favorite',
  'Exercise every day to keep your body healthy',
  'Use your imagination when you play',
  'Brush your teeth twice a day for a bright smile',
  'Be patient – good things take time',
  'Drink plenty of water to stay hydrated',
  "Respect nature and don't litter",
  'Everyone is special in their own way',
  "It's okay to ask for help when you need it",
  'Always wear a helmet when riding your bike',
  'Eat your vegetables to grow big and strong',
  'Be a good friend by sharing and caring',
  'Practice makes progress',
  'Use your words to express how you feel',
  'Look both ways before crossing the street',
  'Treat others the way you want to be treated',
  'Always do your best, no matter what',
  "Use your inside voice when you're indoors",
  'Take turns when playing games with friends',
  'Wash fruits and vegetables before eating them',
  'Stand up straight for good posture',
  'Cover your mouth when you cough or sneeze',
  'Be curious and ask questions to learn new things',
  'Save energy by turning off lights when you leave a room',
  'Eat slowly and chew your food well',
  'Be gentle with books to keep them nice for others',
  'Use sunscreen to protect your skin when playing outside',
  'Celebrate the things that make you unique',
  'Always wear your seatbelt in the car',
  'Try to solve problems on your own before asking for help',
  'Remember to say "excuse me" when you need to interrupt',
  "Don't talk to strangers without a grown-up's permission",
  'Eat a healthy breakfast to start your day right',
  'Take deep breaths when you feel upset to calm down',
  'Use your creativity to make something new',
  'Be a good sport whether you win or lose',
  'Help set the table for family meals',
  "Always tell a grown-up where you're going",
  "Smile – it can brighten someone else's day!",
]
