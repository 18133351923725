import {
  ChineseFlag,
  ChinesePinyinFlag,
  EnglishFlag,
  FrenchFlag,
  GermanFlag,
  ItalianFlag,
  JapaneseFlag,
  KoreanFlag,
  PortugueseFlag,
  RussianFlag,
  SpanishFlag,
} from '../components/LanguageFlags'

export const LANGUAGES = [
  { code: 'en', name: 'English', flag: EnglishFlag },
  { code: 'es', name: 'Spanish', flag: SpanishFlag },
  { code: 'fr', name: 'French', flag: FrenchFlag },
  { code: 'de', name: 'German', flag: GermanFlag },
  { code: 'it', name: 'Italian', flag: ItalianFlag },
  { code: 'pt', name: 'Portuguese', flag: PortugueseFlag },
  { code: 'ru', name: 'Russian', flag: RussianFlag },
  { code: 'ja', name: 'Japanese', flag: JapaneseFlag },
  { code: 'ko', name: 'Korean', flag: KoreanFlag },
  { code: 'zh', name: 'Chinese', flag: ChineseFlag },
  { code: 'zh_py', name: 'Chinese Pinyin', flag: ChinesePinyinFlag },
]

export type Language = (typeof LANGUAGES)[number]
