import { collection, deleteDoc, doc, getDocs, setDoc } from 'firebase/firestore'

import { db } from '../firebase'

const WHITELIST_COLLECTION = 'whitelist'

export const addToWhitelist = async (email: string) => {
  try {
    await setDoc(doc(db, WHITELIST_COLLECTION, email), { email })
  } catch (error) {
    console.error('Error adding user to whitelist:', error)
    throw error
  }
}

export const removeFromWhitelist = async (email: string) => {
  try {
    await deleteDoc(doc(db, WHITELIST_COLLECTION, email))
  } catch (error) {
    console.error('Error removing user from whitelist:', error)
    throw error
  }
}

export const getWhitelistedUsers = async (): Promise<string[]> => {
  try {
    const whitelistRef = collection(db, WHITELIST_COLLECTION)
    const whitelistSnapshot = await getDocs(whitelistRef)
    return whitelistSnapshot.docs.map((doc) => doc.id)
  } catch (error) {
    console.error('Error fetching whitelisted users:', error)
    throw error
  }
}
